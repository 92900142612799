import clsx from 'clsx'
import React, { useMemo } from 'react'
import FormElement from '../FormElement'
import { FormInputProps } from './form-input'

export type { FormInputProps }

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      name,
      label,
      value,
      description,
      helperText,
      error,
      required,
      labelBgColor,
      children,
      prefix,
      suffix,
      containerClassName,
      inputContainerClassName,
      type = 'text',
      ...props
    },
    ref,
  ) => {
    return (
      <FormElement
        name={name}
        label={label}
        description={description}
        required={required}
        labelBgColor={labelBgColor}
        error={error}
        helperText={helperText}
        className={containerClassName}
        containerClassName={inputContainerClassName}
      >
        <div className={clsx('flex justify-start items-center')}>
          {prefix && (
            <span
              className={clsx(
                'self-stretch flex items-center justify-center px-1 text-sm font-medium rounded-l-md text-center border-l border-y',
                'bg-gray-200 dark:bg-slate-700 text-gray-500 dark:text-slate-500 border-gray-300 dark:border-slate-600',
              )}
            >
              {prefix}
            </span>
          )}
          <input
            ref={ref}
            name={name}
            value={value}
            type={type}
            {...props}
            className={clsx(
              'block py-2.5 w-full text-sm',
              'border rounded-md focus:ring-0 peer appearance-none focus:outline-none',
              'bg-white text-gray-900 focus:border-primary-600 dark:focus:bg-slate-900 dark:bg-slate-900 dark:text-slate-400 dark:focus:border-slate-300',
              {
                'border-error-500 focus:border-error-500': !!error,
                'border-gray-300 dark:border-gray-500': !error,
              },
              {
                'pr-2.5 pl-3.5 rounded-r-md': prefix,
                'pl-2.5 pr-3.5 rounded-l-md': suffix,
                'px-2.5 rounded-md': !prefix && !suffix,
              },
              props.className,
            )}
          />
          {suffix && (
            <span
              className={clsx(
                'self-stretch flex items-center justify-center px-1 text-sm font-medium rounded-r-md text-center border-r border-y',
                'bg-gray-200 text-gray-500 border-gray-300 dark:bg-slate-800 dark:text-slate-300 dark:border-gray-600',
              )}
            >
              {suffix}
            </span>
          )}
        </div>
        {children}
      </FormElement>
    )
  },
)
